import { render, staticRenderFns } from "./reports-builder.vue?vue&type=template&id=590fd44c&scoped=true"
import script from "./reports-builder.vue?vue&type=script&lang=js"
export * from "./reports-builder.vue?vue&type=script&lang=js"
import style0 from "./reports-builder.vue?vue&type=style&index=0&id=590fd44c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590fd44c",
  null
  
)

export default component.exports